import React, { createContext, useContext, useCallback } from "react";
import { UserContext } from "../components/AuthGuard";

export const BUNKERING_FEATURE_KEY = "bunkering_";
export const REGION_FEATURE_KEY = "region_";
export const MAP_FEATURE_KEY = "map";
export const LAST_TERMINALS_FEATURE_KEY = "lastTerminals";
export const CAN_ACTIVATE_VESSEL_FINDER_API = "canActivateVesselFinder";

// Create a context for access control
export const AccessControlContext = createContext();

/**
 * AccessControlProvider component that provides access control functionality
 * throughout the application
 */
export const AccessControlProvider = ({ children }) => {
  const [user] = useContext(UserContext);

  /**
   * Check if user is an admin
   * @returns {boolean} - Whether user is an admin
   */
  const isUserAdmin = useCallback(() => {
    return user?.role === "admin";
  }, [user]);

  /**
   * Check if user has a specific feature
   * @param {string} feature - Feature to check
   * @returns {boolean} - Whether user has the feature
   */
  const hasFeature = useCallback(
    (feature) => {
      if (!user || !user.features) return false;
      return user.features.includes(feature);
    },
    [user],
  );

  /**
   * Check if user has access to a specific region
   * @param {string} regionValue - Region value to check (e.g., 'singapore')
   * @returns {boolean} - Whether user has access to the region
   */
  const hasRegionAccess = useCallback(
    (regionValue) => {
      return hasFeature(`${REGION_FEATURE_KEY}${regionValue}`);
    },
    [hasFeature],
  );

  /**
   * Check if user has access to bunkering in a specific region
   * @param {string} regionValue - Region value to check (e.g., 'singapore')
   * @returns {boolean} - Whether user has access to bunkering in the region
   */
  const hasBunkeringAccess = useCallback(
    (regionValue) => {
      return hasFeature(`${BUNKERING_FEATURE_KEY}${regionValue}`);
    },
    [hasFeature],
  );

  /**
   * Check if user has access to map features
   * @returns {boolean} - Whether user has access to map features
   */
  const hasMapAccess = useCallback(() => {
    return hasFeature(MAP_FEATURE_KEY);
  }, [hasFeature]);

  /**
   * Check if user has access to last terminals features
   * @returns {boolean} - Whether user has access to last terminals
   */
  const hasLastTerminalsAccess = useCallback(() => {
    return hasFeature(LAST_TERMINALS_FEATURE_KEY);
  }, [hasFeature]);

  /**
   * Check if user can activate vessel finder
   * @returns {boolean} - Whether user can activate vessel finder
   */
  const canActivateVesselFinder = useCallback(() => {
    return isUserAdmin() && hasFeature(CAN_ACTIVATE_VESSEL_FINDER_API);
  }, [isUserAdmin, hasFeature]);

  // Value object to be provided by the context
  const value = {
    hasFeature,
    hasRegionAccess,
    hasBunkeringAccess,
    hasMapAccess,
    hasLastTerminalsAccess,
    canActivateVesselFinder,
    isUserAdmin,
    // Add any other access control functions here
  };

  return (
    <AccessControlContext.Provider value={value}>
      {children}
    </AccessControlContext.Provider>
  );
};

/**
 * Custom hook to use the access control context
 * @returns {Object} Access control context value
 */
export const useAccessControl = () => {
  const context = useContext(AccessControlContext);
  if (context === undefined) {
    throw new Error(
      "useAccessControl must be used within an AccessControlProvider",
    );
  }
  return context;
};
