import React, { Suspense } from "react";
import ReactDOM from "react-dom/client";
import "./i18n";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter } from "react-router-dom";
import { CircularProgress, Stack } from "@mui/material";
import CssBaseline from "@mui/material/CssBaseline";
import AuthGuard from "./components/AuthGuard";
import Main from "./main";
import { FirebaseAppProvider } from "reactfire";
import FirebaseAppCheckProvider from "./components/FirebaseAppCheckProvider";
import { firebaseConfig } from "./firebase";
import ThemeProvider from "./theme";
import { LicenseInfo } from "@mui/x-license";
import { LocalizationProvider } from "@mui/x-date-pickers-pro/LocalizationProvider";
import { AdapterLuxon } from "@mui/x-date-pickers-pro/AdapterLuxon";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { AccessControlProvider } from "./context/AccessControl";

LicenseInfo.setLicenseKey(
  "fd94cddf773103dd324f76bba9b14787Tz0xMDA3NDIsRT0xNzYxNTgxNzI2MDAwLFM9cHJvLExNPXN1YnNjcmlwdGlvbixQVj1RMy0yMDI0LEtWPTI=",
);

const renderLoader = () => (
  <Stack
    height={"100vh"}
    width={"100vw"}
    alignItems="center"
    justifyContent="center"
  >
    <CircularProgress />
  </Stack>
);

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <ThemeProvider>
    <LocalizationProvider dateAdapter={AdapterLuxon}>
      <FirebaseAppProvider firebaseConfig={firebaseConfig}>
        <FirebaseAppCheckProvider
          recaptchaId={process.env.REACT_APP_RECAPTCHA_ID}
        >
          <CssBaseline />
          <BrowserRouter>
            <AuthGuard>
              <AccessControlProvider>
                <Suspense fallback={renderLoader()}>
                  <ToastContainer />
                  <Main />
                </Suspense>
              </AccessControlProvider>
            </AuthGuard>
          </BrowserRouter>
        </FirebaseAppCheckProvider>
      </FirebaseAppProvider>
    </LocalizationProvider>
  </ThemeProvider>,
);

reportWebVitals();
